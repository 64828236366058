import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from '../../../components/ui/wrapper'

import Heading from '../../../components/ui/heading'
import AboutArea from '../../about-us/about-area'
import { SectionWrap} from './features-area.style'

const ExpertTeam = require("../../../data/images/icons/expert-team.svg");
const SmartSolution = require("../../../data/images/icons/iconfinder-icon-more-solutions.svg");
const EffectiveProcess = require("../../../data/images/icons/effective-process.svg");

const FeaturesArea = ({ missionStyle,subTitlestyle }) => {
    return (
        <SectionWrap>
            <Container>
                <Row >
                    <Col lg={12}>
                        <h4 style={{textAlign:"center"}}>
                                Harnessing the power of Information technology to support organizations across industries.
                        </h4> 
                    </Col>
                </Row>
                <Row>
                    <Col lg={5} md={5} sm={12} ml="auto" mr="auto" >
                        <Heading {...subTitlestyle}>OUR MISSION</Heading>
                        <Heading {...missionStyle}>
                            <i>"To build the best products and services that delight customers and provide compelling human experience"</i>
                        </Heading>
                    </Col>
                    <Col lg={5} md={5} sm={12} ml="auto" mr="auto" >
                        <Heading {...subTitlestyle}>OUR VISION</Heading>
                        <Heading {...missionStyle}>
                            <i>"To use technology to enhance human experience"</i>
                        </Heading>
                    </Col>
                 </Row>
                    <br />
                    <Row justify="center">
                    <Col lg={4} md={4} sm={12} ml="auto" mr="auto" >
                        <div style={{marginLeft:"auto",marginRight:"auto", border:"2px solid #8c8c94", textAlign:"center", backgroundColor:"#0c328e",borderRadius: "100%",height:"340px", width:"340px", boxShadow: "5px #f4f4f4"}}>
                            <img alt ="Expert team Icon" src={ExpertTeam} style={{align:"center", width:"80px",height:"80px", margin:"80px 130px 20px 130px", textAlign:"center"}}/>
                            <h4 style={{color:"#d0d1d2", fontSize:"1.75rem",fontWeight: "300", lineHeight: "1.2"}}>Expert Team </h4>
                            {/* <h5 style={{color:"#d0d1d2", fontSize:"1.2rem",fontWeight: "100", lineHeight: "1.2"}}>Innovative & Experienced</h5> */}
                        </div>
                    </Col>
  
                    <Col lg={4} md={4} sm={12} ml="auto" mr="auto" >
                        <div style={{marginLeft:"auto",marginRight:"auto", textAlign:"center", backgroundColor:"#0c328e",borderRadius: "100%",height:"340px", width:"340px", boxShadow: "5px #f4f4f4"}}>
                            <img alt ="smart solution Icon" src={SmartSolution} style={{align:"center", width:"80px",height:"80px", margin:"80px 130px 20px 130px", textAlign:"center"}}/>
                            <h4 style={{color:"#d0d1d2", fontSize:"1.75rem",fontWeight: "300", lineHeight: "1.2"}}>Smart Solutions </h4>
                            {/* <h5 style={{color:"#d0d1d2", fontSize:"1.2rem",fontWeight: "100", lineHeight: "1.2"}}>Save Time & Cost</h5> */}
                        </div>
                    </Col>

                    <Col lg={4} md={4} sm={12} ml="auto" mr="auto" >
                        <div style={{marginLeft:"auto",marginRight:"auto", textAlign:"center", backgroundColor:"#0c328e",borderRadius: "100%",height:"340px", width:"340px", boxShadow: "5px #f4f4f4"}}>
                            <img alt ="Effective process Icon" src={EffectiveProcess} style={{align:"center",  width:"80px",height:"80px", margin:"80px 130px 20px 130px", textAlign:"center"}}/>
                            <h4 style={{color:"#d0d1d2", fontSize:"1.75rem",fontWeight: "300", lineHeight: "1.2"}}>Effective Process</h4>
                            {/* <h5 style={{color:"#d0d1d2", fontSize:"1.2rem",fontWeight: "100", lineHeight: "1.2"}}>We Deliver Results</h5> */}
                        </div>
                    </Col>

                </Row>
                
                <AboutArea />

               
            </Container>
        </SectionWrap>
    )
}

FeaturesArea.propTypes = {
    serviceStyle: PropTypes.object,
    anchorStyle: PropTypes.object
}

FeaturesArea.defaultProps = {
    featureStyle: {
        mt: "60px",
        responsive: {
            small: {
                mt: "47px"
            }
        }
    },
    ourValuesStyle: {
        marginLeft:"auto",
        marginRight:"auto",
        backgroundColor:"#0c328e", 
        borderRadius: "100%",
        height:"250px", 
        width:"250px", 
        boxShadow: "5px #f4f4f4"
    },
    ourValuesTitleStyle:{
        color:"#fff", 
        textAlign:"center", 
        lineHeight: "250px"
    },
    headingStyle: {
        as: 'h3',
        fontSize: '18px',
        fontweight: 500,
        lineHeight: 1.40,
        color: '#333333',
        mt: '60px',
        textalign: 'center',
        responsive: {
            small: {
                mt: '45px'
            }
        }
    },
    linkStyle: {
        layout: 'underline',
        hover: {
            layout: 2
        }
    },
    missionStyle: {
        as: 'h3',
        fontSize: '18px',
        fontweight: 500,
        hover: {
            layout: 2
        },
        textalign: 'center',
        responsive: {
            small: {
                mt: '45px'
            }
        }
    },
    subTitlestyle:{
        as: 'h3',
        color: '#086ad8',
        fontSize: '18px',
        fontweight: 500,
        lineHeight: 1.40,
        mt: '60px',
        textalign: 'center',
        responsive: {
            small: {
                mt: '45px'
            }
        }
    },
    textStyle: {
        color: 'headingColor',
        fontSize: '18px',
        fontweight: 500,
        lineHeight: 1.40
    },
    anchorStyle: {
        color: 'primary',
        layout: 'underline',
        hover: {
            layout: 2
        }
    }
}

export default FeaturesArea
