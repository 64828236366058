import React from 'react'
import PropTypes from 'prop-types'
import {Container, Row,Col} from '../../../components/ui/wrapper'

import {AboutWrapper} from './about-area.style'

const AboutDesc = () => {

    return (
        <AboutWrapper style={{paddingBottom:'20px'}}>
            <Container>
                <Row>
                    <Col lg={4} md={4} sm={12} ml="auto" mr="auto">
                        <h4 style={{color:"#086ad8"}}>Our Superb Team</h4>
                        <p>
                        We are gifted with a passionate and talented team that is dedicated to deliver.
                        </p>
                    </Col>
                    <Col lg={4} md={4} sm={12} ml="auto" mr="auto" >                         
                        <h4 style={{color:"#086ad8"}}>Expert Partners</h4>
                        <p>
                            We work in partnership with various multinational IT companies so as to fully service our local IT service demands. 

                        </p>
                       
                    </Col>
                    <Col lg={4} md={4} sm={12} ml="auto" mr="auto">
                            <h4 style={{color:"#086ad8"}}>Our Exquisite Clients</h4>
                            <p>
                            GDExperts serves a global clientele ranging from large organizations to start ups by harnessing the power of technology to meet their unique technical needs.
                            </p>
                    </Col>
                </Row>
                {/* <Row>
                    <Col style={{marginTop:'20px'}} lg={12} md={12} sm={12} ml="auto" mr="auto">
                        <h4  style={{color:"#000000"}}>About ISO 27001 Certification</h4>
                        <p>
                        ISO 27001 is one of the world's most popular standards and has been prepared to provide requirements for establishing, implementing, maintaining and continually improving an information security management system.<br />
                        In April 2021, GDExperts became ISO 27001 certified after being audited by one of the world's leading ISO certification companies, <a href="https://www.nemko.com/">Nemko</a>.
                        </p>
                    </Col>
                    <Col className="my-4" lg={6} md={6} sm={12} ml="auto" mr="auto">
                        <h5 style={{color:"#086ad8"}}>Significance of ISO 27001 certification</h5>
                        <li>Full compliance with laws and regulations</li>
                        <li>Optimization of processes</li>
                        <li>Improved image and credibility </li>
                        <li>Improved employee engagement toward process improvement</li>
                        <li>Reduced number of incidents and improved incident resolution</li>
                        <li>Improved customer satisfaction by easily meeting their requirements through producing secure products</li>


                    </Col>
                    <Col className="my-4" lg={6} md={6} sm={12} ml="auto" mr="auto">
                        <h5 style={{color:"#086ad8"}}>Key steps to consider in the process</h5>
                        <li>Gap Analysis</li>
                        <li>Documentation of the mandatory system policies, procedures and processes</li>
                        <li>Internal Audit</li>
                        <li>Management review</li>
                        <li>Corrective Actions</li>
                        <li>Pre-Audit</li>
                        <li>Audit</li>

                    </Col>
                    
                </Row> */}
            </Container>
        </AboutWrapper>
    )
}

AboutDesc.propTypes = {
    sectionStyle: PropTypes.object,
    sectionTitleStyle: PropTypes.object,
    headingStyle: PropTypes.object,
    textStyle: PropTypes.object,
    descStyle: PropTypes.object,
}

AboutDesc.defaultProps = {
    sectionTitleStyle: {
        mb: '30px',
        responsive: {
            small: {
                mb: '30px'
            }
        }
    },
    aboutBoxStyle: {
        mb: "50px",
        backgroundColor: "black"
    },
    linkStyle: {
        layout: 'underline',
        hover: {
            layout: 2
        }
    },
    headingStyle: {
        as: "h3",
        layout: "highlight",
        maxwidth: "330px",
        mb: "24px",
        responsive: {
            medium: {
                maxwidth: "100%"
            }
        }
    },
    textStyle: {
        as: "h4",
        fontSize: "20px"
    },
    descStyle: {
        fontSize: "18px",
        lineHeight: 1.67,
        mb: "23px"
    },
    btnStyle: {
        varient: "texted",
        iconname: "far fa-long-arrow-right",
        hover: "false"
    }
}

export default AboutDesc;